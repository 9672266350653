import React, { useState } from 'react';
import PropTypes from 'prop-types';
//Proptypes
const proptypes = {
    handleOnChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired,
    additionalParams: PropTypes.object,
    isChecked: PropTypes.bool.isRequired,
};
/**
 * Radio Input
 * @param {proptypes} param0
 * @returns
 */
const RadioInput = ({
    handleOnChange,
    id,
    name,
    cssClass,
    additionalParams = {},
    isChecked,
}) => {
    const [checked, setChecked] = useState(isChecked);

    return (
        <input
            type="radio"
            onChange={e => {
                handleOnChange(name, e.target.value);
                setChecked(!checked);
            }}
            id={id}
            checked={checked}
            className={cssClass}
            name={name}
            {...additionalParams}
        />
    );
};
RadioInput.propTypes = proptypes;
export default RadioInput;
